<script setup lang="ts">
import { scrollToPosition } from '@mop/shared/utils/util';
import type { CmsStoryModel } from '@/types/cms';

defineOptions({
  name: 'NewsOverview',
});

const { $mopConfig, $gtm } = useNuxtApp();
const classNameRef = ref(['']);
const { getCmsStoryList, cmsStoryListModelRef, loadingRef } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { getLocalePathByPage } = useMopRouter();
const storyListRef = ref([] as CmsStoryModel[]);
const totalPagesRef = ref(0);
const route = useRoute();
const router = useRouter();
const perPage = 10;

initTransition(classNameRef, loadingRef);

onMounted(() => {
  watch(
    () => [route.params?.category as string, route.query?.page as string],
    async ([category, page], prevValues) => {
      let filterQuery = {};
      const onlyPaginationChanged: boolean = category === prevValues?.[0] && page !== prevValues?.[1];
      if (onlyPaginationChanged) {
        scrollToPosition(0);
      }
      if (category) {
        if (!$mopConfig.getAvailableNewsCategories().includes(category)) {
          createError({
            statusCode: 404,
            message: 'PAGE_NOT_FOUND',
          });
        }
        filterQuery = {
          filter_query: {
            category: {
              in: category,
            },
          },
        };
      }
      await getCmsStoryList('/news', {
        is_startpage: 0 as any, // type wrong in storyblok package
        per_page: perPage,
        page,
        sort_by: 'content.publishedDate:desc',
        ...filterQuery,
      });

      if (cmsStoryListModelRef.value.hasError()) {
        createError({
          statusCode: 404,
          message: 'PAGE_NOT_FOUND',
        });
        return;
      } else {
        $gtm.trackPageView({
          pageType: 'News',
        });
      }

      storyListRef.value = cmsStoryListModelRef.value.getStoryModelList();
      totalPagesRef.value = Math.ceil(cmsStoryListModelRef.value.getTotal() / perPage);
      if (storyListRef.value.length === 0 && page) {
        router.replace(route.path);
      }
    },
    { immediate: true },
  );
});
</script>

<template>
  <div :class="['news-overview-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <template v-if="storyListRef">
        <div v-for="story in storyListRef" :key="story.getUuId()">
          <MopBlogNewsItem :story="story" />
        </div>
        <UiPagination
          v-if="totalPagesRef > 1"
          :key="`news-${totalPagesRef}-${$route.query.fullPath}-${$route.query.page}`"
          :page="`${$route.query.page ?? 1}`"
          :get-locale-path-by-page="getLocalePathByPage"
          :total-pages="totalPagesRef"
          class="news-overview__pagination"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-overview__pagination {
  margin-top: $space30;
}
</style>
