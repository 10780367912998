<script setup lang="ts">
import type { EditableCmsComponent } from '@/types/cms';
import { cmsContentElementModel } from '@/models/cms/cmsContentElementModel';

type CmsCompanyBlogNewsTripleCard = EditableCmsComponent & {
  source: 'blog' | 'news';
  newsCategory: string;
};

defineOptions({
  name: 'CmsCompanyBlogNewsTripleCard',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsCompanyBlogNewsTripleCard>,
    required: true,
  },
});

const cards = ref();
const { $mopConfig } = useNuxtApp();
const { getCmsStoryList } = useMopCms();

onMounted(async () => {
  let filterQuery = {};
  const isNews = props.data.source === 'news';
  if (isNews && $mopConfig.getAvailableNewsCategories().includes(props.data.newsCategory)) {
    filterQuery = {
      filter_query: {
        category: {
          in: props.data.newsCategory,
        },
      },
    };
  }

  await getCmsStoryList(isNews ? '/news' : '/career/career-blog', {
    is_startpage: 0 as any, // type wrong in storyblok package
    per_page: 3,
    page: isNews ? '/news' : '/career/career-blog',
    sort_by: 'content.publishedDate:desc',
    ...filterQuery,
  });

  cards.value = cmsContentElementModel({
    // @ts-ignore
    data: {
      ...props.data,
    },
  });
});
</script>

<template>
  <CmsTripleCard v-if="cards" v-storyblok-editable="data" :data="cards" lazy-load />
</template>
