<script setup lang="ts">
import type { MapStyleElement, MapConfig, GoogleCoordinates, GoogleMapMarkerModel } from '@mop/ui/types/googleMap';
import useUiGoogleMapClient from '@mop/ui/composables/useUiGoogleMapClient';

const mapStyle: Array<MapStyleElement> = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#E9E2D8' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#C8DDF6' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
];

const countryCenter: { [country: string]: GoogleCoordinates } = {
  at: {
    lat: 47.516231,
    lng: 14.550072,
  },
  be: {
    lat: 50.503887,
    lng: 4.469936,
  },
  ch: {
    lat: 46.818188,
    lng: 8.2275119,
  },
  cz: {
    lat: 49.8174919,
    lng: 15.472962,
  },
  de: {
    lat: 51.163375,
    lng: 10.447683,
  },
  ee: {
    lat: 58.595272,
    lng: 25.013607,
  },
  es: {
    lat: 40.463667,
    lng: -3.74922,
  },
  fi: {
    lat: 61.9241099,
    lng: 25.7481511,
  },
  fr: {
    lat: 46.227638,
    lng: 2.213749,
  },
  gb: {
    lat: 55.378051,
    lng: -3.435973,
  },
  ie: {
    lat: 53.41291,
    lng: -8.24389,
  },
  it: {
    lat: 41.87194,
    lng: 12.56738,
  },
  lt: {
    lat: 55.169438,
    lng: 23.881275,
  },
  lu: {
    lat: 49.815273,
    lng: 6.12958299,
  },
  lv: {
    lat: 56.879635,
    lng: 24.603189,
  },
  nl: {
    lat: 52.132633,
    lng: 5.29126599,
  },
  pl: {
    lat: 51.919438,
    lng: 19.145136,
  },
  se: {
    lat: 60.128161,
    lng: 18.643501,
  },
  si: {
    lat: 46.151241,
    lng: 14.995463,
  },
  sk: {
    lat: 48.669026,
    lng: 19.699024,
  },
  ro: {
    lat: 45.943161,
    lng: 24.96676,
  },
  hu: {
    lat: 47.162494,
    lng: 19.503304,
  },
  dk: {
    lat: 56.26392,
    lng: 9.501785,
  },
  gr: {
    lat: 39.074208,
    lng: 21.824312,
  },
  pt: {
    lat: 39.399872,
    lng: -8.224454,
  },
  no: {
    lat: 60.472024,
    lng: 8.468946,
  },
  is: {
    lat: 64.963051,
    lng: 19.020835,
  },
  hr: {
    lat: 45.1,
    lng: 15.2,
  },
  us: {
    lat: 37.09024,
    lng: -95.712891,
  },
  ca: {
    lat: 56.130366,
    lng: -106.346771,
  },
  rs: {
    lat: 44.016521,
    lng: 21.005859,
  },
  tr: {
    lat: 38.963745,
    lng: 35.243322,
  },
  ae: {
    lat: 23.424076,
    lng: 53.847818,
  },
  il: {
    lat: 31.046051,
    lng: 34.851612,
  },
  qa: {
    lat: 25.354826,
    lng: 51.183884,
  },
  nz: {
    lat: -40.900557,
    lng: 174.885971,
  },
  in: {
    lat: 20.593684,
    lng: 78.96288,
  },
  sg: {
    lat: 1.352083,
    lng: 103.819836,
  },
  jp: {
    lat: 36.204824,
    lng: 138.252924,
  },
  hk: {
    lat: 22.396428,
    lng: 114.109497,
  },
};

defineOptions({
  name: 'UiGoogleMap',
});

const props = defineProps({
  mapId: {
    type: String,
    default: '',
  },
  apiKey: {
    type: String,
    required: true,
  },
  region: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    required: true,
  },
  markers: {
    type: Array as PropType<GoogleMapMarkerModel[]>,
    default: () => [],
  },
  selectedMarker: {
    type: Object as PropType<GoogleMapMarkerModel | null>,
    default: () => null,
  },
  selectedMarkerId: {
    type: String,
    default: null,
  },
  forceRender: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['select-marker', 'map-initialized']);

const { apiKey, region, language } = props;
const countryCenterPoint: GoogleCoordinates = countryCenter[region] || countryCenter.de;
const mapConfig: MapConfig = {
  mapStyle,
  language,
  region,
  countryCenterPoint,
};
const { initialize, dispose, createMap, cleanupMap, addMarkers, updateMarker, centerMapAroundMarkers } =
  useUiGoogleMapClient(apiKey, mapConfig);
const mapElementRef = ref<HTMLElement>();

watch(
  // @ts-ignore
  () => [props.selectedMarker, props.selectedMarkerId],
  ([selectedMopMarker, selectedMopMarkerId]: [GoogleMapMarkerModel, string]) => {
    updateMarker(selectedMopMarker, selectedMopMarkerId);
  },
);

function initMarkers(markers: GoogleMapMarkerModel[]) {
  cleanupMap();
  if (!markers.length) {
    return;
  }
  addMarkers(markers, props.selectedMarkerId, handleMarkerClick);
  centerMapAroundMarkers();
}

watch(
  () => props.markers,
  (markers) => {
    initMarkers(markers);
  },
);

watch(
  () => props.forceRender,
  (forceRender) => {
    if (forceRender) {
      centerMapAroundMarkers();
    }
  },
);

function handleMarkerClick(mopMarker: GoogleMapMarkerModel) {
  updateMarker(mopMarker, props.selectedMarkerId);
  emit('select-marker', mopMarker, true);
}

onMounted(async () => {
  await initialize();
  createMap(mapElementRef.value!);
  initMarkers(props.markers);
  emit('map-initialized', true);
});

onBeforeUnmount(dispose);
</script>

<template>
  <div>
    <ClientOnly>
      <div :id="mapId" ref="mapElementRef" class="google-map" />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>
