export function validateAlphabets(inputValue: any): boolean {
  return /^[a-z]+$/i.test(inputValue);
}

export function validateNotEmpty(inputValue: any): boolean {
  if (typeof inputValue === 'object') {
    return inputValue !== null && Boolean(inputValue.length);
  }
  return Boolean(inputValue) && inputValue.toString().trim() !== '';
}

export function validateIsChecked(inputValue: any): boolean {
  return Boolean(inputValue) && inputValue;
}

export function validateCheckboxValues(inputValue: string[]): boolean {
  return Boolean(inputValue?.length);
}

export function validateMax(maxLength: number, inputValue: string): boolean {
  return inputValue?.length <= maxLength;
}

export function validateFileSize(maxSizeInKB: number, inputValue: File): boolean {
  return inputValue && inputValue.size <= maxSizeInKB * 1024;
}

export function validateExactLength(length: number, inputValue: string): boolean {
  return inputValue?.length === length;
}

export function validateStreet(inputValue: string): boolean {
  const regex = /^[^%#*€§${}½µ<@]{2,}$/;
  return regex.test(inputValue);
}

export function validateEmail(inputValue: string): boolean {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"‒–—―+äÄöÖüÜẞß']+(\.[^<>()\[\]\\.,;:\s@"‒–—―+äÄöÖüÜẞß']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regex.test(inputValue);
}

// TODO check pass requirements, currently it’s not only this range, but also number and uppercase and a special char
export function validatePassword(inputValue: string): boolean {
  return /.{8,32}/.test(inputValue);
}

export function validatePhone(inputValue: string): boolean {
  return /^\+?[\d\s\-()/]{7,}$/.test(inputValue);
}

const COUNTRY_REGEX: { [key: string]: RegExp } = {
  de: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
  fr: /^[0-9]{5}$/,
  at: /^[0-9]{4}$/,
  nl: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/,
  se: /^[0-9]{3}\s?[0-9]{2}$/,
  ch: /^[0-9]{4}$/,
  ee: /^[1-9]{1}[0-9]{4}$/,
  pl: /^[0-9]{2}-[0-9]{3}$/,
  fi: /^[0-9]{5}$/,
  gb: /^(GIR 0AA)|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})$/,
  es: /^[0-5]{1}[0-9]{4}$/,
  it: /^[0-9]{5}$/,
  ro: /^[0-9]{6}$/,
  hu: /^[0-9]{4}$/,
  lu: /^[0-9]{4}$/,
  lv: /^(?:LV-)?[0-9]{4}$/,
  lt: /^(?:LT-)?[0-9]{5}$/,
  cz: /^[0-9]{3}\s?[0-9]{2}$/,
  sk: /^[0-9]{3}\s?[0-9]{2}$/,
  si: /^[1-9]{1}[0-9]{3}$/,
  ie: /^.*$/, // The irish have a strange system /^[A-Z0-9]{1,3}$/,
  be: /^(B-)?[1-9]{1}[0-9]{3}$/,
  dk: /^\d{4}$/,
  gr: /^\d{3}\s{0,1}\d{2}$/,
  pt: /^\d{4}$/,
  no: /^\d{4}$/,
  is: /^\d{3}$/,
  hr: /^\d{4}$/,
  us: /^\b\d{5}\b(?:[- ]{1}\d{4})?$/,
  ca: /^(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\s{0,1}\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d$/,
  rs: /^\d{5}$/,
  tr: /^\d{4}$/,
  ae: /^\d{4}|[A-Za-z]\d{4}[a-zA-Z]{3}$/,
  il: /^\b\d{5}(\d{2})?$/,
  nz: /^\d{4}$/,
  in: /^\d{6}$/,
  sg: /^(\d{2}$)|(\d{4}$)|(^\d{6}$)/,
  jp: /^\d{7}\s\(\d{3}-\d{4}\)$/,
};
export function validateZipCode(country: string, inputValue: string): boolean {
  return COUNTRY_REGEX[country.toLocaleLowerCase()].test(inputValue);
}
