<script setup lang="ts">
import { onMounted, onUnmounted, getCurrentInstance, ref, computed, nextTick } from 'vue';
import type { PropType } from 'vue';
import useUi2FormElement from '@mop/ui2/composables/useUi2FormElement';
import type { IconName, UiFormSizes } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2TextInput',
  inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue', 'focusUpdate']);

// Keep in sync with ./Birthday.vue
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  maskedValue: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  icon: {
    type: String as PropType<IconName>,
    default: '',
  },
  inlineIcon: {
    type: String as PropType<IconName>,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  showSuccessIcon: {
    type: Boolean,
    default: false,
  },
  highlightRequired: {
    type: Boolean,
    default: false,
  },
  floating: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<UiFormSizes>,
    default: 'md',
  },
  customError: {
    type: String,
    default: '',
  },
  hideError: {
    type: Boolean,
    default: false,
  },
});

const iconSize = 20;
const isFocusedRef = ref(false);
const isSuccessRef = ref(false);
const { validationErrorsRef, register, unregister, validate } = useUi2FormElement(props);
defineExpose({ validate });
let hasBeenValidated = false;
const hasErrorRef = computed(() => validationErrorsRef.value.length > 0 || props.customError);
const inputElement = ref<HTMLElement>();

async function handleInput(event: Event, paramValue?: string, forceError = false) {
  let value = paramValue ?? ((event.target as HTMLInputElement)?.value || '');
  if (props.type === 'email') {
    const weirdDashesRegex = /‒|–|—|―/g;
    value = value.replace(weirdDashesRegex, '-');
  }
  emit('update:modelValue', value);
  const shouldValidate = props.rules.length && (forceError || hasBeenValidated);
  if (shouldValidate) {
    const isValid = await validate();
    hasBeenValidated = true;
    isSuccessRef.value = isValid;
  }
}

function handleBlur(event: Event) {
  isFocusedRef.value = false;
  const value = (event.target as HTMLInputElement).value?.trim() || '';
  handleInput(event, value, Boolean(value));
  emitFocusValue();
}

function handleClick() {
  inputElement.value?.focus();
}

function handleFocus() {
  isFocusedRef.value = true;
  emitFocusValue();
}

function emitFocusValue() {
  emit('focusUpdate', isFocusedRef.value);
}

onMounted(() => {
  register(getCurrentInstance());
  if (props.autofocus) {
    nextTick(() => {
      inputElement.value?.focus();
    });
  }
});
onUnmounted(() => {
  unregister(getCurrentInstance());
});
</script>

<template>
  <div
    :class="[
      'ui-text-field-wrapper',
      'ui-text-field-wrapper--input',
      `ui-text-field-wrapper--${size}`,
      {
        'ui-text-field-wrapper--floating': floating,
        'ui-text-field-wrapper--has-icon': icon,
        'ui-text-field-wrapper--focused': isFocusedRef,
        'ui-text-field-wrapper--error': hasErrorRef,
        'ui-text-field-wrapper--success': isSuccessRef,
        'ui-text-field-wrapper--filled': Boolean(modelValue),
        'ui-text-field-wrapper--disabled': disabled,
      },
    ]"
  >
    <label v-if="label" :for="id" class="ui-text-field__label">
      <span>{{ label }}</span>
      <span v-if="highlightRequired && required" class="ui-text-field__label-required">
        {{ '*' }}
      </span>
      <span v-else-if="optional" class="ui-text-field__label-optional">
        {{ $ui2Config.ui2T('ui.textFieldInput.optional') }}
      </span>
    </label>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
    <div class="ui-text-field" @mousedown="handleClick" @click="handleClick">
      <Ui2Icon v-if="icon" class="ui-text-field__icon" :name="icon" :width="iconSize" :height="iconSize" />
      <div class="ui-text-field__input-wrapper">
        <Ui2Icon v-if="inlineIcon" class="ui-text-field__inline-icon" :name="inlineIcon" :width="16" :height="16" />
        <input
          v-bind="$attrs"
          :id="id"
          ref="inputElement"
          :name="name"
          class="ui-text-field__input"
          :placeholder="maskedValue ? '' : placeholder"
          :value="modelValue"
          :type="type"
          :disabled="disabled"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
        />
        <div v-if="maskedValue" class="ui-text-field__masked">
          <span class="ui-text-field__masked-ghost">
            {{ modelValue }}
          </span>
          {{ maskedValue }}
        </div>
      </div>
      <div v-if="$slots.innerAppend" class="ui-text-field__input-append"><slot name="innerAppend" /></div>
    </div>
    <Ui2Notification v-if="!hideError && hasErrorRef" type="inline" status="error">
      {{ validationErrorsRef[0] || customError }}
    </Ui2Notification>
    <div v-if="$slots.helperText" class="ui-text-field__helper-text"><slot name="helperText" /></div>
  </div>
</template>

<style lang="scss">
@import './ui2TextField.scss';

.ui-text-field__icon {
  color: $color-text-body-secondary;
}

.ui-text-field__inline-icon {
  color: $color-text-body-primary;
}

.ui-text-field__input-append {
  padding-left: $space-8;
}
</style>
