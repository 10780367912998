<script setup lang="ts">
import type { PropType } from 'vue';
import { ref, provide, onMounted } from 'vue';
import { scrollToErrorElement } from '@mop/shared/utils/util';
import type { UiFormSizes } from '@mop/ui2/types';

defineOptions({
  name: 'UiForm',
});
const props = defineProps({
  floating: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<UiFormSizes>,
    default: 'md',
  },
  autovalidate: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);

const elementsRef = ref<any[]>([]);

function register(element: any) {
  elementsRef.value.push(element);
}

function unregister(element: any) {
  const findIndex = elementsRef.value.findIndex((el) => el.uid === element.uid);
  elementsRef.value.splice(findIndex, 1);
}

provide('register', register);
provide('unregister', unregister);

async function validate() {
  let isValid = true;
  let firstErrorElement: HTMLElement | null = null;
  for (const element of elementsRef.value) {
    if (element.props.customError || !(await element.exposed.validate())) {
      if (firstErrorElement === null) {
        firstErrorElement = element.vnode.el;
      }
      isValid = false;
    }
  }
  if (firstErrorElement) {
    scrollToErrorElement(firstErrorElement);
  }
  return isValid;
}

async function handleSubmit(event: Event) {
  const isValid = await validate();
  if (isValid) {
    emit('submit', event);
  }
  return isValid;
}

onMounted(() => {
  if (props.autovalidate) {
    validate();
  }
});

defineExpose({ validate });
</script>

<template>
  <form class="ui-form" @submit.prevent.stop="handleSubmit">
    <slot />
  </form>
</template>
